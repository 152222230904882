// import dependencies 
import * as cn from '../redux/constants'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Routes, Route, useNavigate, useLocation, useRoutes } from "react-router-dom";
import { useOnlineStatus, getLongAndLat, clearInstallLocalStorage, getBrowserAndOS} from '../utils'
import 'react-html5-camera-photo/build/css/index.css';

// import components
import BottomAlert from '../components/BottomAlert';
import UserPrompt from '../components/UserPrompt';

// import install components 
import QrScanner from '../installpage-components/QrScanner'
import InputKva from '../installpage-components/InputKva'
import UnableToScan from '../installpage-components/UnableToScan'
import TakeInstallPic from '../installpage-components/TakeInstallPic'
import CheckDtmConnectionPage from '../installpage-components/CheckConnectionPage'

// import API redux actions 
import { submitInstallation, getNodeInfo, getMnsList, resetNodeInfo } from '../redux/actions'
import { t } from 'i18next';
import TlnNumInput from '../installpage-components/TlnNumInput';
import MnSNumber from './MnSNumber';
import DigitInputPage from '../components/DigitInputPage';

// import assets 
import unitIcon from '../img/unit-icon.svg'
import primariesIcon from '../img/primaries-icon.svg'
import secondariesIcon from '../img/secondaries-icon.svg'
import prvIcon from '../img/prv-icon.svg'
import pairingIcon from '../img/pairing-icon.svg'
import PairedUnit from '../installpage-components/PairedUnit';
import { db } from '../db';

const Install = () => {
    // Hooks
    const isOnline = useOnlineStatus()
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // Component State 
    const [install,setInstall] = useState(null)
    const [showPairedPrompt, setShowPairedPrompt] = useState(false)
    const [showNotPairedPrompt, setShowNotPairedPrompt] = useState(false)
    const [showDupPrompt, setShowDupPrompt] = useState(false)
    const [dtmNotFoundIssue, setDtmNotFoundIssue] = useState(false)
    const [dtmNotFoundCounter, setDtmNotFoundCounter] = useState(0)
    const [showInstallDone, setShowInstallDone] = useState(false)
    const [showCameraRequiredPrompt, setShowCameraRequiredPrompt] = useState(false)
    const [showLocationRequiredPrompt, setShowLocationRequiredPrompt] = useState(false)
    const [photos, setPhotos] = useState()
    const [takePicture,setTakePicture] = useState()
    const [browserInfo, setBrowserInfo] = useState(null)
    const [cameraErrorDetected, setCameraErrorDetected] = useState(false)

    // Global State 
    const appOnline = useSelector(state => state.appStatus)
    const installationSubmitted  = useSelector(state => state.submitInstallation?.submitted)
    const checkConnectionData = useSelector(state => state.checkConnection?.data)
    const checkConnectionError = useSelector(state => state.checkConnection?.error)
    const nodeInfoState = useSelector(state => state.nodeInfo)
    const nodeInfoNode = useSelector(state => state.nodeInfo?.node)
    const nodeInfoError = useSelector(state => state.nodeInfo?.error)
    const customerSelected = useSelector(state => state.customerSelected)
    const installationTypeSelected = useSelector(state => state.installationTypeSelected?.value)
    
    // Check if there is an install on local storage on first load
    useEffect(() => {
        const installStateFromBefore = localStorage.getItem('installState')
        if(installStateFromBefore) {
            const prevInstall = JSON.parse(installStateFromBefore)
            if(prevInstall) {
                setInstall(prevInstall)
                determineInstallPage(prevInstall)
            } else{
                navigate('scan-unit')
            }
        }
    // eslint-disable-next-line
    }, [])
    
    // Handle the QR Scanner result 
    const handleQrResult = async (result, error) => {
        if (!!result) {
            if (result?.text) {
                
                // Save QR values to state (sku,serila,imei)
                const scannedValues = result.text.split(';')
                const valuesObj = {
                    sku : scannedValues[0],
                    serialNum : scannedValues[1],
                    imei : scannedValues[2],
                }

                setInstall((install) => ({...install, ...valuesObj}))

                
                dispatch(getNodeInfo(valuesObj.serialNum))
            }
        }

        if (!!error) {
            if(error.message){
                if(error.message.includes("permission") || 
                   error.message.includes("Permission") ||
                   error.message.includes("Permissions") ||
                   error.message.includes("permissions") || 
                   error.message.includes("not allowed")
                   ){
                    setShowCameraRequiredPrompt(true)
                   }
            }
        }
    }

    // after scanniing, detect nodeInfoNode change,
    // check if node has been installed already 
    useEffect(() => {
        
        const handleNodeInfo = async () => {
            // node info has been recevied and imei is present 
            if(nodeInfoNode && nodeInfoNode?.imei){

                // make sure imei & serial num are upper case 
                nodeInfoNode.imei.toUpperCase()
                nodeInfoNode.serialNum.toUpperCase()
                
                const obj = { imei : nodeInfoNode.imei, transformerType: nodeInfoNode.transformerType }

                // grab gps location if doing a field install
                if(installationTypeSelected === 'field_install'){
                    dispatch({type: cn.GLOBAL_LOADER_SHOW})
                    try {
                        const position = await getLongAndLat()
                        obj.latitude = position?.coords?.latitude
                        obj.longitude = position?.coords?.longitude  
                    } catch (error) {
                        // handle error grabbing location
                        setShowLocationRequiredPrompt(true)
                        obj.latitude = null
                        obj.longitude = null
                    }
                    dispatch({type: cn.GLOBAL_LOADER_HIDE})

                }
                
                setInstall((install) => ({...install,...obj}))
                
                // if installedState is defined, then unit is already paired
                if(nodeInfoNode.installedState){
                    setShowPairedPrompt(true)
                } else{
                    // unit is not paired 
                    setShowNotPairedPrompt(true)
                }
                return
            }

            // node info received, imei not present 
            if(nodeInfoNode && !nodeInfoNode.imei){
                setDtmNotFoundIssue(true)
            }

            // there was an eror getting the node info 
            if(nodeInfoError){
                if(nodeInfoError === 'duplicate_serialNum') return setShowDupPrompt(true)
                setDtmNotFoundIssue(true)
                return
            }
        }
        handleNodeInfo()
        // eslint-disable-next-line
    }, [nodeInfoNode, nodeInfoError, nodeInfoState])

    // Handle results from imei manual input
    // set the install obj to the serialNumber input
    const handleUnableToScan = async (manualInput) => {
        const obj = { serialNum : manualInput}

        setInstall((install) => ({...install,...obj}))
        dispatch(getNodeInfo(manualInput))
    }

    // Handle results from kva input page, 
    // update install local state with transformerType & kva 
    const handleKvaSubmit = async (transformerType, kvaOverride) => {

        // Save transformer type and kva
        setInstall((install) => ({...install, ...{transformerType, kvaOverride}}))

        mainNavigationHandler('handleKvaSubmit')
    }

    // Handle M&S Selection 
    const handleMnsSubmit = (mns) => {
        if(mns) setInstall((install) => ({...install, ...{mns}}))
        mainNavigationHandler('handleMnsSubmit')
    }

    // Handle DTM not found response 
    const handleDtmNotFoundResponse = continueInstall => {
        dispatch(resetNodeInfo())
        setDtmNotFoundIssue(false)
    }

    // Handle DTM not found try again
    const handleDtmNotFoundTryAgain = () => {
        setDtmNotFoundCounter(dtmNotFoundCounter + 1)
        dispatch(resetNodeInfo())
        setDtmNotFoundIssue(false)
    }

    // Always show at the end of a store room install 
    const handleInstallDoneResponse = (submitInstall) =>{
        if(installationTypeSelected === 'store_room'){
            if(submitInstall) saveFactoryInstallation()
        } else{
            if(submitInstall) saveInstallation()
        }
       
        setShowInstallDone(false)
    }

    // Handle user response to node alreadt paired prompt, if continueInstall = true, go to input kva page
    const handleAlreadyPairedResponse = (continueInstall) => {
        setShowPairedPrompt(false)
        if(!continueInstall) return

        // store room install, go to transformer plate
        if(installationTypeSelected === 'store_room'){
            setInstall((install) => ({...install, ...{answeredPairedPrompt: true}}))
        }

        // field install, if kva is present already, then go to check connection page, else go to transformer picture page 
        if(installationTypeSelected === 'field_install'){
            if(nodeInfoNode.kva){
                // add kva to install object
                setInstall((install) => ({...install, ...{kvaOverride: nodeInfoNode.kva, answeredPairedPrompt: true}}))
            } 
        }
        mainNavigationHandler('handleAlreadyPairedResponse')
    }

    const handleNotPairedResponse = (continueInstall) => {
        setShowNotPairedPrompt(false)
        if(!continueInstall) return
        setInstall((install) => ({...install, ...{kvaOverride: nodeInfoNode.kva, answeredNotPairedPrompt: true}}))
        mainNavigationHandler('handleNotPairedResponse')
    }

    // Handle response from check connection page
    const handleConnectionContinue = (aus_temp_sensor_answer) => {
        const installedAusRelay = aus_temp_sensor_answer.ausState
        const installedTempSensor = aus_temp_sensor_answer.tempState
        setInstall((install) => ({...install, ...{installedAusRelay,installedTempSensor }}))
        handleTakePhotoNavigation('unit-photo')
    }

    const handleTakePictureRes = async (pic,type) => {
        // const isRetakePhoto = location.state?.isRetakePhoto

        const urlParams = new URLSearchParams(window.location.search);
        const isRetakePhoto = urlParams.get('isRetake');

        if(isRetakePhoto){
            db.images.update(type, {value : pic})
        } else{
            db.images.add({key: type, value : pic})
        }
        // wait to update images
        await getImages()
        mainNavigationHandler('handleTakePictureRes_' + type)
    }

    // Save install to local storage on every change
    useEffect(() => {
        if(install) {
            localStorage.setItem('installState', JSON.stringify(install))
        }
    }, [install])

    // submit pairing to api
    const saveFactoryInstallation = async () => {
        // Add null location to avoid backend error 
        let currentLocation = {
            latitude : null,
            longitude : null,
        }

        // Add customer
        const results = {...install, ...{
            installationType: 'store_room',
            customer : customerSelected, 
            installationStatus :{successful : true},
            tln : '',
            ...currentLocation,
            deviceTime : Date.now(),
            installType: installationTypeSelected
        }}

                
        results.photos = photos
       
        // update the photo notes with the acutal base64 encoded image from 
        if(results.notes && results.notes.length > 0){
            for(const note of results.notes){
                if(note.type === 'photo'){
                    const photo = await db.images.get(note.value)
                    if(photo){
                        note.value = photo.value
                    }
                }
            }
        }

        dispatch({type: cn.GLOBAL_LOADER_SHOW})

        await dispatch(submitInstallation(results))
        navigate('/')
    }   
    


    // Submit Installation 
    // Get GPS location 
    // Add Timestamp, customer, gps & install sucecesfull to results
    // Submit results and navigate to home page
    const saveInstallation = async (tlnNumber) => {

        dispatch({type: cn.GLOBAL_LOADER_SHOW})

        // if fields are empty do not submit
        if(!install.imei && !install.kvaOverride ) return alert('There has been an error')
        if(!customerSelected) return alert('There has been an error')

        // add whether installation was successful or not
        let installationWasSuccessful

        if(!checkConnectionError){
            if(checkConnectionData?.payloads?.length > 0){
                installationWasSuccessful = true
            } else{
                installationWasSuccessful = false
            }
        } else{
            installationWasSuccessful = false
        }

        // Add Timestamp, customer, gps & install sucecesfull to results
        const results = {...install, ...{
                installationType: 'field_install',
                deviceTime : Date.now(), 
                customer : customerSelected, 
                installationStatus : {successful: installationWasSuccessful },
                tln : tlnNumber ? tlnNumber : '',
                installType: installationTypeSelected
            }}

        // check if latitude and longitude are defined
        if(!results.latitude) results.latitude = null
        if(!results.latitude) results.longitude = null

        results.photos = photos

        //submit install 
        dispatch({type: cn.GLOBAL_LOADER_SHOW})
        await dispatch(submitInstallation(results, photos))
        clearInstallLocalStorage()
        navigate('/')
    }

    // Depending on prev install saved on storage, restore where user was
    const determineInstallPage = async prevInstall => {
        if(!prevInstall) return navigate('scan-unit')

        const images = await db.images.toArray()
        const imagesSaved = images.map(image => image.key)

        const nodeScannedPassed = prevInstall.imei && (prevInstall.answeredPairedPrompt || prevInstall.answeredNotPairedPrompt)
        const platePicPassed = imagesSaved.includes('platePic')
        const kvaPassed = prevInstall.kvaOverride
        const mnsPassed = prevInstall.mns

        const unitPhotoPassed = imagesSaved.includes('unitPhoto')
        const primariesPhotoPassed = imagesSaved.includes('primariesPhoto')
        const secondariesPhotoPassed = imagesSaved.includes('secondariesPhoto') 
        const prvPhotoPassed = imagesSaved.includes('prvPhoto') 
        const pairingPhotoPassed = imagesSaved.includes('pairingPhoto')

        const urlParams = new URLSearchParams(window.location.search);
        const isRetakePhoto = urlParams.get('isRetake');

        // store room install
        if(installationTypeSelected === 'store_room'){
            if(!nodeScannedPassed) return navigate('scan-unit')
            if(!platePicPassed) return handleTakePhotoNavigation('transformer-plate')
            if(!kvaPassed) return navigate('input-kva')
            if(!mnsPassed && customerSelected.isFPL) return navigate('select-mns')
            if(!unitPhotoPassed) return handleTakePhotoNavigation('unit-photo', isRetakePhoto)
            if(!primariesPhotoPassed) return handleTakePhotoNavigation('primaries-photo', isRetakePhoto)
            if(!secondariesPhotoPassed) return handleTakePhotoNavigation('secondaries-photo', isRetakePhoto)
            if(!prvPhotoPassed) return handleTakePhotoNavigation('prv-photo', isRetakePhoto)
            if(!pairingPhotoPassed) return handleTakePhotoNavigation('pairing-photo', isRetakePhoto)
            
            return navigate('paired-unit')
        }
        
        // deployment install
        if(installationTypeSelected === 'field_install'){
            //todo
        }
    }

    const handlePairedUnitSubmit = async () => {
        if(installationTypeSelected === 'field_install' && customerSelected.isFPL ){
            navigate('tln')
        } else{
            setShowInstallDone(true)
        }
    }
    const handleSetNotes = (notes) => {
        setInstall((install) => ({...install, notes}))
    }

    // on installation submit, clear & go to home page
    useEffect(() => {
        if(installationSubmitted){
            clearInstallLocalStorage()
            navigate('/')
        }
    // eslint-disable-next-line
    }, [installationSubmitted])

    // after submiting input kva (kva and transformer type, get mns list)
    useEffect(() => {
        if(install?.kvaOverride && install?.transformerType && location.pathname === '/install/select-mns'){
            dispatch(getMnsList(install.kvaOverride, install?.transformerType))
        }
    }, [install, dispatch, location])

    const mainNavigationHandler = (type) => {
        const isFieldInstall = installationTypeSelected === 'field_install'
        const isStoreRoomInstall = installationTypeSelected === 'store_room'

        // const isRetakePhoto = location.state?.isRetakePhoto

        const urlParams = new URLSearchParams(window.location.search);
        const isRetakePhoto = urlParams.get('isRetake');

        // need to go back to paired unit 
        if(isRetakePhoto) {
            const ratekePhotoType = type.split('_')[1]
            navigate('paired-unit', {state : {fromRetake : ratekePhotoType}})
            return
        }

        switch (type) {
            case 'handleNotPairedResponse' : 
            case 'handleAlreadyPairedResponse':
                handleTakePhotoNavigation('transformer-plate')
                // if(isStoreRoomInstall) return handleTakePhotoNavigation('transformer-plate')
                // if(isFieldInstall && nodeInfoNode.kva) return navigate('connection-verification')
                // if(isFieldInstall) return navigate('input-kva')
                break
            case 'handleKvaSubmit':
                if(isFieldInstall) return navigate('connection-verification')
                if(isStoreRoomInstall && customerSelected.isFPL) return  navigate('select-mns')
                return handleTakePhotoNavigation('unit-photo')
            case 'handleTakePictureRes_platePic': return navigate('input-kva')
            case 'handleMnsSubmit' : return handleTakePhotoNavigation('unit-photo',(isRetakePhoto))
            case 'handleTakePictureRes_unitPhoto': return handleTakePhotoNavigation('primaries-photo', (isRetakePhoto))
            case 'handleTakePictureRes_primariesPhoto': return handleTakePhotoNavigation('secondaries-photo', (isRetakePhoto))
            case 'handleTakePictureRes_secondariesPhoto': return handleTakePhotoNavigation('prv-photo', (isRetakePhoto))
            case 'handleTakePictureRes_prvPhoto': return handleTakePhotoNavigation('pairing-photo', (isRetakePhoto))
            case 'handleTakePictureRes_pairingPhoto': return navigate('paired-unit')
            default:
                break;
        }
    }

    const handleTakePhotoNavigation = (picPage, isRetake) => {
        let path = ''
        switch (picPage) {
            case 'transformer-plate':
                setTakePicture({type : 'platePic', text : 'transformer_plate_text'})
                path = 'take-picture/transformer-plate'
                break;
            case 'unit-photo':
                setTakePicture({type : 'unitPhoto', text : 'unit_photo_text', icon : unitIcon})
                path = 'take-picture/unit-photo'
                break;
            case 'primaries-photo':
                setTakePicture({type : 'primariesPhoto', text : 'primaries_photo_text', icon : primariesIcon})
                path = 'take-picture/primaries-photo'
                break;
            case 'secondaries-photo':
                setTakePicture({type : 'secondariesPhoto', text : 'secondaries_photo_text', icon : secondariesIcon})
                path = 'take-picture/secondaries-photo'
                break;
            case 'prv-photo':
                setTakePicture({type : 'prvPhoto', text : 'prv_photo_text', icon : prvIcon})
                path = 'take-picture/prv-photo'
                break;
            case 'pairing-photo':
                setTakePicture({type : 'pairingPhoto', text : 'pairing_photo_text', icon : pairingIcon})
                path = 'take-picture/pairing-photo'
                break;
            default:
                break;
        }

        if(isRetake){
            navigate(path + '?isRetake=true')
        } else{
            navigate(path)
        }
    }

    const handleRetakeBtnClick = (photoTypePage) => {
        handleTakePhotoNavigation(photoTypePage, true)
    }


    const getImageUrlByType = (photoName) => {
        switch (photoName) {
            case 'unitPhoto': return 'unit-photo'
            case 'primariesPhoto': return 'primaries-photo'
            case 'secondariesPhoto': return 'secondaries-photo'
            case 'prvPhoto': return 'prv-photo'
            case 'pairingPhoto': return 'pairing-photo'
            default : return 'unit-photo'
        }
    }

    useEffect(() => {
        // perstst take picutres 
        if(location.pathname.includes('take-picture')){
            const photoType = location.pathname.split('/')[3]

            const urlParams = new URLSearchParams(window.location.search);
            const isRetakePhoto = urlParams.get('isRetake');

            // handle picture being blank
            if(!takePicture){
                handleTakePhotoNavigation(photoType, isRetakePhoto)
            } 
            // make sure selected path is the same as take picture 
            else{
                if(getImageUrlByType(takePicture.type) !== photoType){
                    handleTakePhotoNavigation(photoType, isRetakePhoto)
                }
            }
        }

        // load new images if we are on paired unit page
        if(location.pathname === '/install/paired-unit'){
            getImages()
        }
    }, [location.pathname])

    useEffect(() => {
        // get browser type
        const browserAndOs = getBrowserAndOS()
        setBrowserInfo(browserAndOs)
    }, []) 

    const getImages = async () => {
        const images = await db.images.toArray()
        // filter out the plate pic
        setPhotos(images)
        return images
    }        

    const handleCameraRequiredPrompt = ({tryAgain}) => {
        setShowCameraRequiredPrompt(false)
        if(tryAgain){
            navigate('/')
            return 
        }
        
        // if os is android just go to unable to scan and continue instal
        if(browserInfo.os === "Android"){
            navigate('unable-to-scan')
        } 
        else{
            // if os is something else, refresh the page 
            navigate('/')
        }
    }

    const handleLocationRequiredPrompt = ({tryAgain}) => {
        setShowLocationRequiredPrompt(false)
        if(tryAgain){
            window.location.reload()
        }
    }

    return (
        //container py-4 px-4
        <div className={"" + (location.pathname && (location.pathname === "/install/scan-unit" || location.pathname === "/install/installed-unit" ) ? "" : "")}>

            {/* Show if app is offline or not */}
            {(!appOnline || !isOnline) && <BottomAlert text={t('offline_data')} icon={true}/>}
            <Routes>
                <Route path="scan-unit" element={<QrScanner handleQrResult={handleQrResult} browserInfo={browserInfo}/>}/>

                <Route path="input-kva" element={
                    <InputKva handleKvaSubmit={handleKvaSubmit}/>} 
                />

                {/* Mns Selection */}
                <Route path="select-mns" element={
                    <MnSNumber handleMnsSubmit={handleMnsSubmit} kva={install?.kvaOverride} transformerType={install?.transformerType}/>
                } />
                
                {/* Manual MnS Input */}
                <Route path="input-mns" element={
                    <DigitInputPage onSubmit={handleMnsSubmit} 
                    text={'input_mns_text'}
                    size={9}
                    dashes={[2,7,8]}
                    />
                } />

                <Route path="unable-to-scan" element={<UnableToScan handleUnableToScan={handleUnableToScan} handleGoBack={() => {navigate('scan-unit')}}/>} />

                <Route path="connection-verification" element={
                    <CheckDtmConnectionPage 
                        imei={install?.imei}
                        handleConnectionContinue={handleConnectionContinue}
                        transformerType={install?.transformerType}
                    />} />

                {/* Handle take install pictures  */}
                <Route path='take-picture/*' element={<>
                    {takePicture && <TakeInstallPic 
                        submit={(pic) => handleTakePictureRes(pic,takePicture.type)} 
                        text={takePicture.text} icon={takePicture.icon} cameraErrorDetected={cameraErrorDetected} 
                        setCameraErrorDetected={() => {setCameraErrorDetected(true)}}
                        browserInfo={browserInfo}
                        />}
                </>} />
    
                <Route path="paired-unit" element={<PairedUnit submit={handlePairedUnitSubmit} notes={install?.notes} setNotes={handleSetNotes} 
                photos={photos && photos.filter(img => ['unitPhoto','primariesPhoto','secondariesPhoto','prvPhoto','pairingPhoto'].includes(img.key))}
                handleRetakeBtnClick={handleRetakeBtnClick}/>} />

                <Route path="tln" element={<TlnNumInput submit={saveInstallation} />} />

            </Routes>

            {/* Show try again prompt if its manual input and counter is 0 */}
            {dtmNotFoundIssue && location.pathname === '/install/unable-to-scan' && dtmNotFoundCounter === 0 && (
                <UserPrompt data={
                {
                    questionTitle: t('dtm_not_found_title'), 
                    questionText: <div>
                                    <span>{t('dtm_not_found_text', {dtmSerial : nodeInfoState?.error?.serialNum,customer : customerSelected.name})}</span>
                                    <br /><br />
                                    <span>{t('dtm_not_found_verify_text')}</span>
                                    <br /><br />
                                </div> ,
                    disagreeText: t('cancel'),
                    agreeText : t('try_again'),
                    onAgree: () => handleDtmNotFoundTryAgain(),
                    onDisagree: () => handleDtmNotFoundResponse(false)
                }} 
            />)}

            {/* Show regular prompt if location is /install/scan-unit or counter is more than 0 */}
            {dtmNotFoundIssue && (location.pathname === '/install/scan-unit' || dtmNotFoundCounter > 0) && (
                <UserPrompt data={
                {
                    questionTitle: t('dtm_not_found_title'), 
                    questionText: <div>
                                    <span>{t('dtm_not_found_text', {dtmSerial : nodeInfoState?.error?.serialNum,customer : customerSelected.name})}</span>
                                </div> ,
                    agreeText : t('ok'),
                    onAgree: () => handleDtmNotFoundResponse(true)
                }} 
            />)}

            {showPairedPrompt && (location.pathname === '/install/scan-unit' || location.pathname === '/install/unable-to-scan') && (
                <UserPrompt data={
                {
                    questionTitle: t('unit_already_paired_title'), 
                    questionText: <span>{t( nodeInfoNode?.transformerSerialNum ? 'unit_already_paired_text' : 'unit_already_paired_text_no_transformer_serial', {
                        dtmSerial : nodeInfoNode?.serialNum?.substring(nodeInfoNode?.serialNum.length - 4),
                        transformerSerial : nodeInfoNode?.transformerSerialNum?.substring(nodeInfoNode?.transformerSerialNum?.length - 4)
                    })}<br/>{t('unit_already_paired_subtext_store_room')}</span>, 
                    disagreeText: t('cancel'),
                    agreeText : t('force_install'),
                    onAgree: () => handleAlreadyPairedResponse(true),
                    onDisagree: () => handleAlreadyPairedResponse(false)
                }
            } />)} 

            {showNotPairedPrompt && (location.pathname === '/install/scan-unit' || location.pathname === '/install/unable-to-scan') && (
                <UserPrompt data={
                {
                    questionTitle: t('unit_not_paired_title'), 
                    questionText: t('unit_not_paired_text'), 
                    disagreeText: t('cancel'),
                    agreeText : t('pair_unit'),
                    onAgree: () => handleNotPairedResponse(true),
                    onDisagree: () => handleNotPairedResponse(false)
                }
            } />)} 

            {showDupPrompt && (
                <UserPrompt data={
                {
                    questionTitle: t('duplicate_prompt_title'), 
                    questionText: <div> <p className='mb-4'>{t('duplicate_prompt_text_1')}</p> <p className='mb-4'>{t('duplicate_prompt_text_2')}</p> </div>, 
                    agreeText : t('ok'),
                    onAgree: () => setShowDupPrompt(false),
                }
            } />)} 

            {showInstallDone && (
                <UserPrompt data={
                {
                    questionTitle: t('confirm'), 
                    questionText: <div>{t('confirm_pairing_text',{
                        dtmSerial : install.serialNum,
                        kva : install.kvaOverride,
                        transformerType : install.transformerType
                    })}<br/></div>, 
                    agreeText : t('complete'),
                    disagreeText : t('cancel'),
                    onAgree: () => handleInstallDoneResponse(true),
                    onDisagree: () => handleInstallDoneResponse(false)
                }
            } />)}

            {showCameraRequiredPrompt && browserInfo?.os === 'Android' && (
                <UserPrompt data={
                {
                    questionTitle: t('camera_required_title'), 
                    questionText: <div>{browserInfo?.os === 'Android' ? t('camera_recommended_text') : t('camera_required_text')}<br/></div>, 
                    disagreeText : t('try_again'),
                    agreeText : t('continue'),
                    onAgree: handleCameraRequiredPrompt,
                    onDisagree : () => {handleCameraRequiredPrompt({tryAgain : true})}
                }
            } />)}
            {showCameraRequiredPrompt && browserInfo?.os !== 'Android' && (
                <UserPrompt data={
                {
                    questionTitle: t('camera_required_title'), 
                    questionText: <div>{browserInfo?.os === 'Android' ? t('camera_recommended_text') : t('camera_required_text')}<br/></div>, 
                    agreeText : t('ok'),
                    onAgree: handleCameraRequiredPrompt
                }
            } />)}

            {showLocationRequiredPrompt && (
                <UserPrompt data={
                {
                    questionTitle: t('location_required_title'), 
                    questionText: <div>{t('location_required_text')}<br/></div>, 
                    agreeText : t('continue'),
                    disagreeText : t('try_again'),
                    onAgree: () => {handleLocationRequiredPrompt(false)},
                    onDisagree : () => {handleLocationRequiredPrompt({tryAgain : true})}
                }
            } />)}

        </div>
    )
}

export default Install