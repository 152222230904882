import * as cn from './constants'
import jwt_decode from "jwt-decode";
import { b64toBlob, clearInstallLocalStorage } from '../utils';
import { db } from '../db';
const SERVER = process.env.REACT_APP_SERVER
const REQUEST_TIMEOUT_DEFAULT = 30000

export const login = (encryptedCredentials) => async (dispatch, getState) => {
  
  const { appStatus } = getState()

  if(!appStatus.online){
    dispatch({
      type:  cn.USER_LOGIN_FAIL,
      payload : 'offline_login'
    })
    return
  }

    // dispatch login here
    dispatch({
      type: cn.USER_LOGIN_REQUEST
    })

    // set justLoggedIn to true 
    dispatch({
      type : cn.USER_CLEAR_JUST_LOGGED_IN_SET
    })

    // build post request
    const reqOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify({ input: encryptedCredentials })
    };

    try {
      const response = await fetchWithTimeout(SERVER + '/login', reqOptions)
      const res = await response.json()

      if(res.data.access_token){
        const access_token = res.data.access_token
        const decoded_token = jwt_decode(access_token)
        
        // save token to storage
        localStorage.setItem('access_token', access_token )

        dispatch({
          type: cn.USER_LOGIN_SUCCESS,
          payload : {...decoded_token, access_token}
        })

        // login is successful so load customers
        dispatch(getCustomers())

      } else{
        dispatch({
          type:  cn.USER_LOGIN_FAIL,
          payload : res.data.message
        })
      }
      
    } catch (error) {
      // handle time out here
      if(error.name === 'AbortError'){
        dispatch({
          type:  cn.USER_LOGIN_FAIL,
          payload : 'timeout_login'
        })
        return
      }
      dispatch({
        type:  cn.USER_LOGIN_FAIL,
        payload : error
      })
    }
}

export const logout = () => async(dispatch) => {
  dispatch({
    type:  cn.USER_LOGOUT
  })

  // save language and saved installs 
  const language = localStorage.getItem('i18nextLng')
  const savedInstalls = []

  for(let key in localStorage){
    if(key.split('_')[0] === "installSaved" ){
      const installObj = JSON.parse(localStorage.getItem(key))
      savedInstalls.push(installObj)
    }
  }

  // clear all storage 
  localStorage.clear()
  clearInstallLocalStorage()

  // write back language and saved installations 
  if(language) localStorage.setItem('i18nextLng', language)
  if(savedInstalls.length > 0){
    savedInstalls.forEach(install => {
      localStorage.setItem('installSaved_' + install.serialNum, JSON.stringify(install))
    })
  }

  dispatch({type : cn.CUSTOMER_LIST_RESET})
  dispatch({type : cn.CUSTOMER_SELECTED_CLEAR})
  dispatch({type : cn.USER_CLEAR_JUST_LOGGED_IN_CLEAR})
  dispatch({type : cn.CHECK_CONNECTION_RESET})
  dispatch({type : cn.SUBMIT_INSTALLATION_RESET})
  dispatch({type : cn.INSTALLATION_LIST_RESET})
  dispatch({type : cn.INSTALLATION_UNPAIR_RESET})
  dispatch({type : cn.INSTALLATION_TYPE_CLEAR})
  dispatch({type : cn.NODE_RESET})
}

export const getCustomers = () => async(dispatch, getState) => {

  dispatch({
    type: cn.CUSTOMER_LIST_REQUEST
  })

  const { userLogin } = getState()

    const token = userLogin.user.access_token

    // build get request
    const reqOptions = {
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }

  try {
    const response = await fetchWithTimeout(SERVER + '/customers', reqOptions)
    const data = await response.json()

    dispatch({
      type: cn.CUSTOMER_LIST_SUCCESS,
      payload : data
    })

    // if data only has 1 customer, then select that customer 
    if(data.length === 1 && data[0].customer_id){
      dispatch({
        type: cn.CUSTOMER_SELECTED_SET,
        payload : data[0] 
      })
    }

  } catch (error) {
    // handle time out here
    console.log(error)

    if(error.name === 'AbortError'){
      dispatch({
        type:  cn.CUSTOMER_LIST_FAIL,
        payload : 'timeout_get_customers'
      })
      return
    }

    dispatch({
      type: cn.CUSTOMER_LIST_FAIL,
      payload : error
    })
  }
}

export const getMnsList = (kva,transformerType) => async(dispatch, getState) => {

  dispatch({
    type: cn.MNS_LIST_REQUEST
  })

  const { userLogin, customerSelected } = getState()

  // translate transformer types
  switch (transformerType) {
    case 'Single Phase Pad Mount': transformerType = 'single_phase'
      break;
    case 'Three Phase Pad Mount': transformerType = 'three_phase'
      break;
    case 'Aerial': transformerType = 'aerial'
      break;
    default:
      break;
  }

  try {
    const token = userLogin.user.access_token

    // build get request
    const reqOptions = {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json','Authorization': 
        'Bearer ' + token, 
        'current-customer-id' : customerSelected.customer_id},
      body: JSON.stringify({ kva,transformerType })
    }

    

    fetchWithTimeout(SERVER + '/mns-list', reqOptions)
      .then(response => response.json())
      .then(data => {

        // handle error 
        if(data.status === "failed"){
          dispatch({
            type: cn.MNS_LIST_FAIL,
            payload : data.message
          })
          // error so just set default 
          dispatch({
            type: cn.MNS_LIST_DEFAULT
          })
          return
        }
        // handle empty list 
        if(data.length < 1) {
          dispatch({
            type: cn.MNS_LIST_DEFAULT
          })
          return
        }

        dispatch({
          type: cn.MNS_LIST_SUCCESS,
          payload : data
        })
      })
      .catch(err => {
        dispatch({
          type: cn.MNS_LIST_FAIL,
          payload : err
        })
        // error so just set default 
        dispatch({
          type: cn.MNS_LIST_DEFAULT
        })
      })
  } catch (error) {
    dispatch({
      type: cn.MNS_LIST_FAIL,
      payload : 'error'
    })
  }
}

export const submitInstallation = (installation, installOptions) => async(dispatch, getState) => {
  const isOfflineInstall = installOptions?.isOfflineInstall
  const installationCopy = JSON.parse(JSON.stringify(installation));
  console.log('Submitting install for ', installation.serialNum)

  // show loader 
  dispatch({
    type: cn.SUBMIT_INSTALLATION_REQUEST
  })
  
  // get userlogin, token & customer selected
  const { userLogin, installationTypeSelected, appStatus} = getState()
  const token = userLogin.user.access_token
  const { customerSelected } = getState()
  const installationNotes = installation.notes

  // add installationType to install object 
  installation.installType = installation.installType || installationTypeSelected.value
  
  // not storing transformer serial num for now
  installation.transformerSerial = ''

  // build post request
  const formdata = new FormData();
  
  // append photos to request 
  installation.photos.forEach(photo => {
    if(photo?.value){
      const base64Image = photo.value.split(',')[1]
      const blobImage = b64toBlob(base64Image)
      const photoApiName = getPhotoApiName(photo.key)
      if(blobImage) formdata.append(photoApiName,blobImage)
    } 
  })

  // remove photos and notes from installation and json object as text
  delete installation.photos
  delete installation.notes
  formdata.append("json", JSON.stringify(installation));

  // set req options
  const reqOptions = {
    method: 'POST',
    headers: { 
      'Authorization': 'Bearer ' + token,
      'current-customer-id' : customerSelected.customer_id
    },
    body: formdata,
    redirect: 'follow'
  }

  const reqUrl = `${SERVER}/activate/${installation.serialNum}?customer=${customerSelected.customer_id}`
  
  fetchWithTimeout(reqUrl, reqOptions,60000)
  .then(response => response)
  .then(async res => {

    // if there are notes, call the add notes method 
    if(installationNotes){
      await dispatch(submitNotes(installationNotes,installation.serialNum))
    }

    // if offline installation remove installation from offline list 
    if(isOfflineInstall){
      await dispatch(removeOfflineInstall(installation.serialNum))
    }

    // installation error 
    if(res.status === "failed"){
      dispatch({
        type : cn.SUBMIT_INSTALLATION_FAIL,
        payload : res.message
      })
      return false
    } else{
      dispatch({
        type : cn.SUBMIT_INSTALLATION_SUCCESS,
        payload : installation
      })
      dispatch({type : cn.NODE_RESET})

      // clear installation 
      clearInstallLocalStorage()
      return true
    }
  })
  .catch(error => {    
    dispatch({type : cn.NODE_RESET})
    // if api call fails because of offline, app will retry automatically 
    if(!appStatus.online){
      dispatch({
        type : cn.SUBMIT_INSTALLATION_FAIL,
        payload : 'offline_submit_install_msg'
      })
      // save install to local storage to try again later 
      dispatch(addOfflineInstall(installationCopy))
      return false
    } else{
      if(error.name === 'AbortError'){
        dispatch({
          type:  cn.SUBMIT_INSTALLATION_FAIL,
          payload : 'timeout_submit_install'
        })
        // save install to local storage to try again later 
        dispatch(addOfflineInstall(installationCopy))
        return false
      }
      dispatch({
        type : cn.SUBMIT_INSTALLATION_FAIL,
        payload : error
      }) 
    }
  })
}

export const addOfflineInstall = (installation) => async dispatch => {

  try {
    dispatch({
      type: cn.OFFLINE_INSTALLATION_ADD_REQUEST
    })
  
    await db.pendingInstalls.add({
      key : installation.serialNum,
      installation
    }) 

    dispatch({
      type: cn.OFFLINE_INSTALLATION_ADD_SUCCESS
    })

    await dispatch(getOfflineInstalls())
  } catch (error) {
    dispatch({
      type: cn.OFFLINE_INSTALLATION_ADD_FAIL,
      payload : error
    })
  }

}

export const removeOfflineInstall = (serialNum) => async dispatch => {
  try {
    dispatch({
      type: cn.OFFLINE_INSTALLATION_REMOVE_REQUEST
    })
  
    await db.pendingInstalls.delete(serialNum) 

    dispatch({
      type: cn.OFFLINE_INSTALLATION_REMOVE_SUCCESS
    })

    await dispatch(getOfflineInstalls())
  } catch (error) {
    dispatch({
      type: cn.OFFLINE_INSTALLATION_REMOVE_FAIL
    })
  }
}

export const getOfflineInstalls = () => async (dispatch) => {
  try {
    dispatch({
      type: cn.OFFLINE_INSTALLATION_LIST_REQUEST
    })

    let offlineInstalls = []
    const keys = await db.pendingInstalls.orderBy('key').keys();

    for (const key of keys) {
      const pendingDbInstall = await db.pendingInstalls.get({ key });
      if (pendingDbInstall?.installation) {
        offlineInstalls.push(pendingDbInstall.installation);
      }
    }
  
    dispatch({
      type: cn.OFFLINE_INSTALLATION_LIST_SUCCESS,
      payload: offlineInstalls
    })
  } catch (error) {
    dispatch({
      type : cn.OFFLINE_INSTALLATION_LIST_FAIL,
      payload: error
    })
  }
}

export const submitNotes = (notes, serialNum) => async(dispatch, getState) => {
  // show loader 
  dispatch({
    type: cn.SUBMIT_NOTES_REQUEST
  })

  // get userlogin, token & customer selected
  const { userLogin } = getState()
  const token = userLogin.user.access_token
  const { customerSelected } = getState()
  
  const reqOptions = {
    method: 'POST',
    headers: { 
      'Authorization': 'Bearer ' + token,
      'current-customer-id' : customerSelected.customer_id
    },
    redirect: 'follow'
  }

  for (const note of notes){
    const formdata = new FormData();
    formdata.append("serialNum", serialNum)
    switch (note.type) {
      case 'text':
        formdata.append("text", note.value)
        break;
      case 'photo':
        // convert to blob
        const image = note.value.split('base64,')[1]
        const blobImage = b64toBlob(image)
        formdata.append("file", blobImage)
        break;
      case 'voice':
        formdata.append("file", note.value)
        break;
    
      default:
        break;
    }

    reqOptions.body = formdata

    fetchWithTimeout(`${SERVER}/add-note?customer=${customerSelected.customer_id}&type=${note.type}`, 
      reqOptions)
    .then(response => response)
    .then(res => {

      if(res.status === "failed"){
        note.success = false
      } else{
        note.success = true
      }
    })
    .catch(error => {
      note.success = false
    })
  }
  // compare notes with sucess notes 
  const notesCount = notes.length
  const successNoteCount = notes.filter(note => note.sucess)
  
  if(successNoteCount === 0){
    dispatch({
      type: cn.SUBMIT_NOTES_FAIL,
      payload: {
        submittedNotes : successNoteCount,
        totalNotes : notesCount
      }
    })
  } else if (successNoteCount < notesCount){
    dispatch({
      type: cn.SUBMIT_NOTES_PARTIAL,
      payload: {
        submittedNotes : successNoteCount,
        totalNotes : notesCount
      }
    })
  } else{
    dispatch({
      type: cn.SUBMIT_NOTES_SUCCESS
    })
  }
  // // clear local storage for install
  // clearInstallLocalStorage()
}

export const unpairInstallation = (installation) => async(dispatch, getState) => {
 
  dispatch({
    type: cn.INSTALLATION_UNPAIR_REQUEST
  })

  const { userLogin, installationTypeSelected } = getState()
  const token = userLogin.user.access_token

  const { customerSelected } = getState()

  const reqOptions = {
    method: 'POST',
    headers: { 
      'Authorization': 'Bearer ' + token,
      'current-customer-id' : customerSelected.customer_id
    },
    redirect: 'follow'
  }

  fetchWithTimeout(`${SERVER}/deactivate/${installation.serialNum}?customer=${customerSelected.customer_id}&installType=${installationTypeSelected.value}`, reqOptions)
  .then(response => {
    if(response.status === "200" || response.status === 200){
      dispatch({
        type : cn.INSTALLATION_UNPAIR_SUCCESS,
        payload : installation
      })
      // remove from storage 
      localStorage.removeItem('installSaved_' + installation.serialNum)
    } else{
      dispatch({
        type : cn.INSTALLATION_UNPAIR_FAIL,
        payload : 'Error unpairing'
      })
    }
  })
  .catch(err => dispatch({
    type : cn.INSTALLATION_UNPAIR_FAIL,
    payload : err
  }))


}

export const getInstallations = () => async(dispatch, getState) => {
  dispatch({
    type: cn.INSTALLATION_LIST_REQUEST
  })

  const { userLogin, installationTypeSelected } = getState()
  const token = userLogin.user?.access_token

  const { customerSelected } = getState()

  if(!token){
    dispatch({
      type: cn.INSTALLATION_LIST_FAIL,
      err: 'User needs to be singed in'
    })
    return
  }

  if(!customerSelected){
    dispatch({
      type: cn.INSTALLATION_LIST_FAIL,
      err: 'A customer needs to be selected'
    })
    return
  }


  // build get request
  const reqOptions = {
    method: 'GET',
    headers: { 
      'Authorization': 'Bearer ' + token,
      'current-customer-id' : customerSelected.customer_id
    }
  }

  // if online load from api 
  fetch(`${SERVER}/activations?customer=${customerSelected.customer_id}&installType=${installationTypeSelected.value}`, reqOptions)
  .then(response => response.json())
  .then(data => {
    // set submittedToDb as true, since these come from db &     
    // make sure imeis and serial nums are upper case 
    // TODO ***: make changes on the api so it returns these values 
  
    const installationsFromDB = data.length > 0 && data.map(item => (
      { ...item, ...{submittedToDB : true, 
        imei : item.imei.toUpperCase(), 
        serialNum: item.serialNum.toUpperCase(), 
        customer_id : customerSelected.customer_id
      }}) )

    completeSubmission(installationsFromDB)
  })
  .catch(err => {
    completeSubmission()
    // dispatch({
    //   type: cn.INSTALLATION_LIST_FAIL,
    //   payload : err
    // })
  })

  const completeSubmission = newInstalls => {
    let installListData = newInstalls ? newInstalls : []
    const { customerSelected, installationTypeSelected } = getState()

    // load from local storage only installs that match customer id and installation type 
    for(let key in localStorage){
      if(key.split('_')[0] === "installSaved" ){
        const installObj = JSON.parse(localStorage.getItem(key))
        const currentSerialNum = key.split('_')[1]
        const installStorageCustomerID = installObj.customer?.customer_id || installObj.customer_id
        
        // if customer ids don't match, skip item in loop 
        if(customerSelected.customer_id !== installStorageCustomerID) continue

        // if install type doesn't match current install type, skip item in loop 
        if( (installationTypeSelected.value !== installObj.installedState) && (installationTypeSelected.value !== installObj.installType)  ) continue

        const foundOnDb = installListData.find(item => item.serialNum.toUpperCase() === currentSerialNum.toUpperCase())
        
        // only push installations that are not on db and if the customer matches
        if(!foundOnDb){
          installListData.push(installObj)
        } 
      }
    }

    // set install data 
    dispatch({
      type: cn.INSTALLATION_LIST_SUCCESS,
      payload : installListData
    })

    // for each installation, save on localstorage 
    installListData.forEach(item => {

      // TODO** : make sure get activations returns customer_id and installedState so offline activations from storage can be displayed 
    
      // if customer id is already defined on install saved from before, make sure to add it when saving install
      const prevInstall = localStorage.getItem('installSaved_' + item.serialNum.toUpperCase())
      const prevCustomerId = prevInstall ? JSON.parse(prevInstall).customer_id : null
      const prevInstalledState = prevInstall ? JSON.parse(prevInstall).installedState : null

      if(prevCustomerId){
        item.customer_id = prevCustomerId
        item.installedState = prevInstalledState
      }

      localStorage.setItem('installSaved_' + item.serialNum.toUpperCase(), JSON.stringify(item))
    })

  }
}

export const getInstallationsReport = (options) => async(dispatch, getState) => {
  dispatch({
    type: cn.INSTALLATION_REPORT_REQUEST
  })

  const { userLogin } = getState()
  const token = userLogin.user?.access_token

  const { customerSelected } = getState()

  if(!token){
    dispatch({
      type: cn.INSTALLATION_REPORT_FAIL,
      err: 'User needs to be singed in'
    })
    return
  }

  if(!customerSelected){
    dispatch({
      type: cn.INSTALLATION_REPORT_FAIL,
      err: 'A customer needs to be selected'
    })
    return
  }


  // build get request
  const reqOptions = {
    method: 'GET',
    headers: { 
      'Authorization': 'Bearer ' + token,
      'current-customer-id' : customerSelected.customer_id
    }
  }
  
  let url = `${SERVER}/installs-report?customer=${customerSelected.customer_id}`

  // optional filters 
  if(options.from) url += `&from=${options.from}`
  if(options.to) url += `&to=${options.to}`
  if(options.installType) url += `&installType=${options.installType}`
  if(options.serialNum) url += `&serialNum=${options.serialNum}`
  if(options.kva) url += `&kva=${options.kva}`
  if(options.mns) url += `&mns=${options.mns}`
  if(options.email) url += `&email=${options.email}`

  // if online load from api 
  fetch(url, reqOptions)
  .then(response => response.json())
  .then(data => {  
    dispatch({
      type: cn.INSTALLATION_REPORT_SUCCESS,
      payload : data
    })
  })
  .catch(err => {
    dispatch({
      type: cn.INSTALLATION_REPORT_FAIL,
      payload : err
    })
  })

}
export const checkConnection = (imei, checkSnc = false, checkForTemperature = false) => async(dispatch, getState) => {
  dispatch({
    type: cn.CHECK_CONNECTION_REQUEST
  })

  const { customerSelected, userLogin, appStatus } = getState()
  const token = userLogin.user?.access_token


  if(!token){
    dispatch({
      type: cn.CHECK_CONNECTION_FAIL,
      err: 'user_needs_sign_in'
    })
    return
  }

  if(!customerSelected){
    dispatch({
      type: cn.CHECK_CONNECTION_FAIL,
      err: 'customer_needs_selection'
    })
    return
  }

  if(!appStatus.online){
    dispatch({
      type: cn.CHECK_CONNECTION_FAIL,
      err: 'offline_check_connection_msg'
    })
    return
  }

  // build get request
  const reqOptions = {
    method: 'GET',
    headers: { 
      'Authorization': 'Bearer ' + token,
      'current-customer-id' : customerSelected.customer_id
    }
  }

  fetchWithTimeout(`${SERVER}/check-connection/${imei}?customer=${customerSelected.customer_id}&checkSnc=${checkSnc}&checkTemp=${checkForTemperature}`,
  reqOptions)
    .then(response => response.json())
    .then(data => {

      if(data.code === 404){
        dispatch({
          type: cn.CHECK_CONNECTION_FAIL,
          payload : data.message
        })
        return
      }
      
      dispatch({
        type: cn.CHECK_CONNECTION_SUCCESS,
        payload : data
      })
      
      // if node is already on install list and it is unsuccessful, change to successful 
      const { installationList } = getState()
      const installs = installationList?.installations

      const successFromCheckConn = data.successful
      let replaceInstallArr = false

      if(installs){
        // build new installs arr 
        const newInstallList = installs.map(install => {

          // if imei matches & success from the check conn api is different than from the install list
          if((install.imei === imei ) && (install.installationStatus?.successful !== successFromCheckConn)){
              
              // make the obj have a successful install flag
              install.installationStatus.successful = true
              replaceInstallArr = true
              return install
          } else{
            return install
          } 
        })

        // replace installs array if there was any change to the array 
        if(replaceInstallArr){
          dispatch({
            type: cn.INSTALLATION_LIST_SUCCESS,
            payload : newInstallList
          })
        }
        
      }
    })
    .catch(error => {

      if(error.name === 'AbortError'){
        dispatch({
          type:  cn.CHECK_CONNECTION_FAIL,
          payload : 'timeout_check_connection'
        })
        return
      }
      dispatch({
        type: cn.CHECK_CONNECTION_FAIL,
        payload : 'error_check_connection'
      })
    })
}

export const getNodeInfo = (serialNum) => async(dispatch, getState) => {
  dispatch({
    type: cn.NODE_REQUEST
  })

  const { userLogin, installationTypeSelected} = getState()
  const token = userLogin.user?.access_token

  const { customerSelected } = getState()


  if(!token){
    dispatch({
      type: cn.NODE_FAIL,
      err: 'User needs to be signed in'
    })
    return
  }

  if(!customerSelected){
    dispatch({
      type: cn.NODE_FAIL,
      err: 'A customer needs to be selected'
    })
    return
  }


  // build get request
  const reqOptions = {
    method: 'GET',
    headers: { 
      'Authorization': 'Bearer ' + token,
      'current-customer-id' : customerSelected.customer_id
    }
  }

  fetchWithTimeout(`${SERVER}/nodes/${serialNum}?customer=${customerSelected.customer_id}`, reqOptions)
    .then(response => response.json())
    .then(data => {

      if(!data){
        dispatch({
          type: cn.NODE_FAIL,
          payload :  data?.message ? {message : data.message, serialNum} : {error: 'error', serialNum}
        })
        return
      }

      if(data.hasOwnProperty('imei')){
        // make sure serialNum and imei are capitalized 
        data.imei = data.imei.toUpperCase()
        data.serialNum = data.serialNum.toUpperCase()
        dispatch({
          type: cn.NODE_SUCCESS,
          payload : data
        })
      } else{
        dispatch({
          type: cn.NODE_FAIL,
          payload :  data?.message ? {message : data.message, serialNum} : {error: 'error', serialNum}
        })
      }

    })
    .catch(error => {
      if(error.name === 'AbortError'){
        dispatch({
          type:  cn.NODE_FAIL,
          payload : 'timeout_get_node_info'
        })
        return
      }

      dispatch({
        type: cn.NODE_FAIL,
        payload : error
      })
    })
}

export const resetNodeInfo = () => async(dispatch) => {
  dispatch({
    type: cn.NODE_RESET
  })
}

export const changePassword = (encryptedCredentials) => async (dispatch, getState) => {
  const { appStatus } = getState()

  if(!appStatus.online){
    dispatch({
      type:  cn.CHANGE_PASSWORD_FAIL,
      payload : 'You need to be connected to change your password'
    })
    return
  }

    // dispatch login here
    dispatch({
      type: cn.CHANGE_PASSWORD_REQUEST
    })

    // build post request
    const reqOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ input: encryptedCredentials })
    };
    

    try {
      fetchWithTimeout(SERVER + '/change-password', reqOptions)
      .then(response => response.json())
      .then(res => {

        if(res?.status !== 'failed'){
          dispatch({
            type: cn.CHANGE_PASSWORD_SUCCESS,
            payload : 'success'
          })
        } else{
          dispatch({
            type:  cn.CHANGE_PASSWORD_FAIL,
            payload : 'fail'
          })
          // clear state after 3 seconds s
          setTimeout(()=>{
            dispatch({
              type:  cn.CHANGE_PASSWORD_RESET,
            })
          }, 3000)
        }
      })
    } catch (error) {
      dispatch({
        type:  cn.CHANGE_PASSWORD_FAIL,
        payload : error
      })
    }
}

export const resetPassword = (email) => async (dispatch, getState) => {
  const { appStatus } = getState()

  if(!appStatus.online){
      


    dispatch({
      type:  cn.RESET_PASSWORD_FAIL,
      payload : 'You need to be connected to reset your password'
    })
    return
  }

    // dispatch login here
    dispatch({
      type: cn.RESET_PASSWORD_REQUEST
    })

    // build post request
    const reqOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email : email })
    };
    

    try {
      fetchWithTimeout(SERVER + '/reset-password', reqOptions)
      .then(response => response.json())
      .then(res => {

        if(res?.status !== 'failed'){
          dispatch({
            type: cn.RESET_PASSWORD_SUCCESS,
            payload : 'success'
          })
        } else{
          dispatch({
            type:  cn.RESET_PASSWORD_FAIL,
            payload : 'fail'
          })
          // clear state after 3 seconds s
          setTimeout(()=>{
            dispatch({
              type:  cn.RESET_PASSWORD_RESET,
            })
          }, 3000)
        }
      })
    } catch (error) {
      dispatch({
        type:  cn.RESET_PASSWORD_FAIL,
        payload : error
      })
    }
}

async function fetchWithTimeout(resource, options = {}, timeout = REQUEST_TIMEOUT_DEFAULT) {

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  const response = await fetch(resource, {
    ...options,
    signal: controller.signal  
  });
  clearTimeout(id);

  return response;
}

export const getFile = async (filename, retries = 3) => {
  const token = localStorage.getItem('access_token')
  const customer_id = JSON.parse(localStorage.getItem('customer'))['customer_id']

  const options = {
      method: 'GET',
      headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
      }
  }

  try {
      const response = await fetch(`${SERVER}/get-file/${filename}?customer=${customer_id}`, options);

      if (!response.ok) {
          throw new Error(`HTTP error, status: ${response.status}`);
      }

      const data = await response.blob();
      return data;
  } catch (error) {
      if (retries > 0) {
          console.log(`Retrying... Attempts left: ${retries - 1}`);
          return await getFile(filename, retries - 1);
      } else {
          throw error;
      }
  }
};

export const getPhotoApiName = (input) => {
  switch (input) {
    case 'pairingPhoto': return 'installation-photo'
    case 'platePic': return 'nameplate-photo'
    case 'primariesPhoto': return 'primaries-pic'
    case 'prvPhoto': return 'prv-pic'
    case 'secondariesPhoto': return 'secondaries-pic'
    case 'unitPhoto': return 'unit-pic'
    default: return input
  }
}
